import validate from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.14_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__isdrjzwj63wkr3ww5m5p22lmqq/node_modules/nuxt/dist/pages/runtime/validate.js";
import _01_45auth_45global from "/home/runner/work/checkup/checkup/apps/admin/src/middleware/01.auth.global.ts";
import _10_45check_45organization_45global from "/home/runner/work/checkup/checkup/apps/admin/src/middleware/10.checkOrganization.global.ts";
import _11_45check_45location_45global from "/home/runner/work/checkup/checkup/apps/admin/src/middleware/11.checkLocation.global.ts";
import _12_45check_45exists_45locations_45global from "/home/runner/work/checkup/checkup/apps/admin/src/middleware/12.checkExistsLocations.global.ts";
import _99_45navigate_45root_45page_45global from "/home/runner/work/checkup/checkup/apps/admin/src/middleware/99.navigateRootPage.global.ts";
import manifest_45route_45rule from "/home/runner/work/checkup/checkup/apps/admin/node_modules/.pnpm/nuxt@3.13.2_@parcel+watcher@2.5.0_@types+node@20.17.14_db0@0.2.1_eslint@8.57.1_ioredis@5.4.1__isdrjzwj63wkr3ww5m5p22lmqq/node_modules/nuxt/dist/app/middleware/manifest-route-rule.js";
export const globalMiddleware = [
  validate,
  _01_45auth_45global,
  _10_45check_45organization_45global,
  _11_45check_45location_45global,
  _12_45check_45exists_45locations_45global,
  _99_45navigate_45root_45page_45global,
  manifest_45route_45rule
]
export const namedMiddleware = {
  "check-setup": () => import("/home/runner/work/checkup/checkup/apps/admin/src/middleware/checkSetup.ts"),
  "location-setting-authorization": () => import("/home/runner/work/checkup/checkup/apps/admin/src/middleware/locationSettingAuthorization.ts"),
  "organization-setting-authorization": () => import("/home/runner/work/checkup/checkup/apps/admin/src/middleware/organizationSettingAuthorization.ts")
}